import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LogInPage from './pages/auth/LogInPage';
import HomePage from './pages/HomePage';

export const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path="/" exact>
          <HomePage />
        </Route>
        <Route path="/login">
          <LogInPage />
        </Route>
      </Switch>
    </Router>
  );
};
