import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

const LogInPage = () => {
  const [emailValue, setEmailValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');

  const history = useHistory();

  const handleLogIn = async () => {
    alert('Log In Button clicked');
  };

  const LogInWrapper = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  `;
  const LogInBox = styled.div`
    background: #fff;
    padding: 2rem;
    width: 22rem;
  `;
  const SignUpBox = styled(LogInBox)`
    text-align: center;
    margin-top: 1rem;
  `;
  const Logo = styled.div`
    margin-bottom: 1.6rem;
    font-size: 2rem;
    text-align: center;
  `;
  const Input = styled.input`
    padding: 0.6rem 0.5rem;
    font-size: 1rem;
    margin-bottom: 1rem;
  `;
  const Button = styled.button`
    padding: 0.3rem 0.6rem;
  `;

  return (
    <LogInWrapper>
      <LogInBox>
        <Logo>😂 Hahaha</Logo>
        <Input
          value={emailValue}
          onChange={(e) => setEmailValue(e.target.value)}
          type="email"
          placeholder="Email"
          aria-label="Email"
        />
        <Input
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
          type="password"
          placeholder="Password"
          aria-label="Password"
        />
        <Button disabled={!emailValue || !passwordValue} onClick={handleLogIn}>
          Log In
        </Button>
        <hr />
        <Button onClick={() => history.push('/forgot-password')}>
          Forgot Password?
        </Button>
      </LogInBox>
      <SignUpBox>
        <p>
          Don't have an Account?{' '}
          <Button onClick={() => history.push('/signup')}>Sign Up</Button>
        </p>
      </SignUpBox>
    </LogInWrapper>
  );
};

export default LogInPage;
