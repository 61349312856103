import { ReactComponent as Logo } from '../logos/logo.svg';
import { ReactComponent as HomeIcon } from '../logos/house-door.svg';
import styled from 'styled-components';

const NavWrapper = styled.div`
  background-color: #fff;
  border-bottom: 1px solid #444;
`;
const Nav = styled.nav`
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 60rem;
  margin: 0 auto;
`;
const LogoLink = styled.a`
  display: flex;
  align-items: center;
  text-decoration: none;
`;
const LogoTitle = styled.div`
  color: #000;
  font-size: 1.5rem;
  margin: 0 0.5rem;
`;
const Input = styled.input`
  padding: 0.2rem 0.6rem;
  font-size: 1rem;
  min-width: 15rem;

  &: focus {
    outline: none;
  }
`;

const Navbar = () => {
  return (
    <NavWrapper>
      <Nav>
        <LogoLink href="/">
          <Logo width={40} />
          <LogoTitle>Hahaha</LogoTitle>
        </LogoLink>
        <form>
          <Input type="text" placeholder="Search" aria-label="Search" />
        </form>
        <div>
          <a href="/">
            <HomeIcon width={36} height={36} />
          </a>
        </div>
      </Nav>
    </NavWrapper>
  );
};

export default Navbar;
